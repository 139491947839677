import "./assets/normalize.css";
import MainFoot from "./components/Foot/MainFoot.vue"; // import MainHead2 from "./components/Head/MainHead2.vue";

import MainHead from "./components/Head/MainHead.vue";
export default {
  name: "App",
  components: {
    MainHead: MainHead,
    MainFoot: MainFoot // MainHead2

  },
  setup: function setup() {
    return {};
  }
};