import { createRouter, createWebHashHistory,createWebHistory } from "vue-router";


const routes = [
  {
    path: "/",
    redirect: "/homenew",
  },
  {
    path: "/homenew",
    name: "HomeNew",
    component: () => import("../views/newHome/HomeNew.vue"),
  },
  {
    path: "/introduction",
    name: "Introduction",
    component: () => import("../views/deviceIntroduction/introduction.vue"),
  },
  {
    path: "/software",
    name: "Software",
    component: () => import("../views/software/software.vue"),
  },
  {
    path: "/buy",
    name: "Buy",
    component: () => import("../views/buy/buy.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/news/news.vue"),
  },


  // {
  //   path: "/home",
  //   name: "Home",
  //   component: () => import("../views/Home/Home.vue"),
  // },
  // {
  //   path: "/equip",
  //   name: "Equip",
  //   component: () => import("../views/Equip/Equip.vue"),
  // },
];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // mode: "history"
});
export default router;
